import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { debugLog } from "../util/logger";

/**
 * UserManagementNav component
 * @returns UserManagementNav component
 */
function UserManagementNav() {
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /**
   * menu items for user management menu
   */
  const menuItems = [
    {
      key: "profile",
      label: "Profile",
      component: Link,
      to: "/me",
      onClick: handleCloseUserMenu,
    },
    {
      key: "dashboard",
      label: "Dashboard",
      component: Link,
      to: "/events",
      onClick: handleCloseUserMenu,
    },
    {
      key: "logout",
      label: "Logout",
      component: Button,
      onClick: () => logout(),
    },
  ];

  /**
   * logs user info when authenticated
   */
  useEffect(() => {
    if (isAuthenticated) debugLog("User is authenticated", user);
  }, [isAuthenticated, user]);

  return (
    <>
      {isAuthenticated && (
        <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={user?.given_name} src={user?.picture} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-user-management"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {/* map through settings and render menu items */}
            {menuItems.map((item) => (
              <MenuItem
                key={item.key}
                component={item.component}
                {...(item.component === Link && { to: item.to })}
                onClick={item.onClick}
              >
                <Typography textAlign="center">{item.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
      {!isAuthenticated && (
        <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#c24444",
              color: "white",
              width: "120px",
              borderRadius: "64px",
              "&:hover": {
                color: "#c24444",
                backgroundColor: "#EAEAEA",
              },
            }}
            onClick={() => loginWithRedirect()}
          >
            <PermIdentityIcon sx={{ mr: 0.75 }}  />
            Login
          </Button>
        </Box>
      )}
    </>
  );
}

export default UserManagementNav;
