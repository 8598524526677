import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Category } from "../types";
import { getCategories } from "../api/search";

interface CategoryState {
  categories: Category[];
  selectedCategories: string[];
  filteredCategories: string[];
  error: string | null;
}

const initialState: CategoryState = {
  categories: [],
  selectedCategories: [],
  filteredCategories: [],
  error: null,
};

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (_, { rejectWithValue }) => {
    try {
      return await getCategories();
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setSelectedCategories(state, action: PayloadAction<string[]>) {
      state.selectedCategories = action.payload;
    },
    setFilteredCategories(state, action: PayloadAction<string[]>) {
      state.filteredCategories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCategories.fulfilled,
        (state, action: PayloadAction<Category[]>) => {
          state.categories = action.payload;
        },
      )
      .addCase(fetchCategories.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export const { setSelectedCategories, setFilteredCategories } =
  categorySlice.actions;
export default categorySlice.reducer;
