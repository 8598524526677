import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { ReactComponent as LocalBeatName } from "../resources/LocalBeatLogoName.svg";
import { Link } from "react-router-dom";
import UserManagementNav from "./UserManagementNav";
import AnimatedLogo from "./AnimatedLogo";
import { Box } from "@mui/material";

function LocalBeatAppBar() {
  return (
    <AppBar position="sticky" style={{ boxShadow: "none" }} id="Appbar">
      <Toolbar disableGutters id="Toolbar">
        <Box
          component={Link}
          to={"/"}
          sx={{
            display: { xs: "flex" },
            p: 1,
            height: "100%",
          }}
          alignItems="center"
          columnGap={0}
        >
          <AnimatedLogo size="3em" repeat={1} clickable={true} />
          <LocalBeatName style={{ paddingTop: ".2em" }} id="local-beat-logo" aria-label="Local Beat Logo" />
        </Box>
        <UserManagementNav />
      </Toolbar>
    </AppBar>
  );
}

export default LocalBeatAppBar;
