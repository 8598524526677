import { useEffect, useState } from "react";
import { motion } from "motion/react";

type AnimatedLogoProps = {
  size?: string;
  repeat?: number;
  duration?: number;
  clickable?: boolean;
  style?: React.CSSProperties;
};

/**
 * Animated Logo component
 * *** DO NOT SET REPEAT TO INFINITY IF YOU WANT THE LOGO TO BE CLICKABLE ***
 * @param size - The size of the logo
 * @param repeat - The number of times the logo should animate
 * @param duration - The duration of the animation
 * @param clickable - Whether the logo should be clickable
 * @returns The AnimatedLogo component
 */
const AnimatedLogo = ({
  size = "100px",
  repeat = Infinity,
  duration = 1.5,
  clickable = false,
  style = {},
}: AnimatedLogoProps) => {
  if (repeat === Infinity) {
    clickable = false;
  }

  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setIsAnimating(false);
    }, duration * 1000);
  };

  const pinAnimation = {
    animate: isAnimating ? { y: [0, -5, 1.5, -0.5, 0.5, -0.1, 0] } : {},
    transition: {
      repeat: repeat,
      duration: duration,
      ease: "easeInOut",
    },
  };

  useEffect(() => {
    setIsAnimating(true);
    if (repeat !== Infinity) {
      return () => {
        setTimeout(() => {
          setIsAnimating(false);
        }, duration * 1000);
      };
    }
  }, []);

  return (
    <motion.svg
      viewBox="-16 -7 90 90"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: size,
        height: size,
        cursor: clickable ? "pointer" : "default",
        ...style
      }}
      onClick={clickable ? handleClick : undefined}
      id="logo"
      aria-label="Local Beat Logo"
    >
      {/* Pin in the Middle */}
      <motion.path
        d="M31.903 14.5491H28.313V48.2134H31.903V14.5491Z"
        fill="#13143a"
        {...pinAnimation}
      />
      {/* Pin Circle */}
      <motion.path
        d="M30.1089 15.6975C25.9101 15.6975 22.502 12.2764 22.502 8.07927C22.502 3.88212 25.9101 0.453125 30.1089 0.453125C34.3078 0.453125 37.7159 3.8742 37.7159 8.07135C37.7159 12.2685 34.2999 15.6896 30.1089 15.6896V15.6975ZM30.1089 4.0405C27.8869 4.0405 26.084 5.84607 26.084 8.07135C26.084 10.2966 27.8869 12.1022 30.1089 12.1022C32.3309 12.1022 34.1338 10.2966 34.1338 8.07135C34.1338 5.84607 32.3309 4.0405 30.1089 4.0405Z"
        fill="#CB2026"
        {...pinAnimation}
      />
      {/* Innermost Circle */}
      <motion.path
        d="M16.9907 42.4561C18.414 35.2655 25.5782 30.6091 32.9716 32.0662C40.0804 33.4679 44.8961 40.5634 43.5044 47.5877C42.1206 54.5883 34.9722 59.316 27.8555 57.9222C20.5332 56.4968 15.599 49.4646 16.9828 42.4482L16.9907 42.4561ZM28.5197 54.2081C33.6833 55.2297 38.7915 51.8799 39.7958 46.8117C40.7842 41.8384 37.2733 36.7385 32.1888 35.7565C26.9382 34.7429 21.8142 37.9976 20.81 42.9946C19.7583 48.2133 23.1427 53.1391 28.5197 54.2081Z"
        fill="#13143a"
        animate={isAnimating ? { scale: [1, 1, 1.15, 0.95, 1.1, 0.98, 1] } : {}}
        transition={{
          repeat: repeat,
          duration: 1.5,
          ease: "easeInOut",
          delay: 0.05,
        }}
      />
      {/* Second full circle */}
      <motion.path
        d="M26.5358 64.7861C16.6198 63.1547 9.63754 54.214 9.4952 45.2495C9.33705 35.0972 16.6198 26.5524 26.6623 25.0794C35.4633 23.7886 44.1219 27.7719 48.2259 34.9942C54.8523 46.6512 48.9692 60.8661 36.0405 64.4456C32.9013 65.3167 29.7304 65.4355 26.5279 64.7861H26.5358ZM27.3265 61.3254C28.6391 61.3808 29.9518 61.5551 31.2486 61.4759C43.4893 60.7711 51.5628 47.8708 44.6596 36.4751C41.2594 30.8525 35.9298 28.4609 29.4457 28.5163C20.3126 28.5955 12.8559 36.5226 13.3066 45.6455C13.6862 53.24 19.5298 59.7178 27.3186 61.3333L27.3265 61.3254Z"
        fill="#13143a"
        animate={
          isAnimating ? { scale: [1, 1, 1.12, 0.95, 1.05, 0.98, 1] } : {}
        }
        transition={{
          repeat: repeat,
          duration: duration,
          ease: "easeInOut",
          delay: 0.1,
        }}
      />
      {/* Outer Circle with gap */}
      <motion.path
        d="M53.8864 49.7573C55.9186 39.019 50.4388 28.3281 40.934 23.9488C40.1432 23.5845 39.2813 23.3549 38.4827 23.0064C37.3835 22.5234 36.9091 21.5889 37.2412 20.6703C37.597 19.6883 38.6962 19.1577 39.8428 19.5378C42.9188 20.5436 45.7654 22.0324 48.2009 24.1706C56.5749 31.5274 59.9435 40.7533 57.3024 51.6104C54.7483 62.1033 47.6791 68.6208 37.344 71.3925C20.7384 75.843 3.46848 63.6396 2.45633 46.5263C1.82373 35.7484 6.48122 27.3778 15.5906 21.5572C16.3576 21.0662 17.1721 20.6386 17.9866 20.2426C19.0541 19.7358 20.0109 20.0288 20.5328 20.9316C21.0626 21.8582 20.77 22.8956 19.7499 23.5449C18.2159 24.5269 16.5553 25.3426 15.1399 26.4671C1.11997 37.6569 3.89549 58.4842 19.9476 66.1579C30.1324 71.0361 41.7564 68.4545 48.9363 60.0126C51.5695 56.9163 53.2933 53.3606 53.9022 49.7573H53.8864Z"
        fill="#13143a"
        animate={
          isAnimating ? { scale: [1, 1, 1.07, 0.98, 1.05, 0.99, 1] } : {}
        }
        transition={{
          repeat: repeat,
          duration: duration,
          ease: "easeInOut",
          delay: 0.15,
        }}
      />
      {/* Outermost partial circle */}
      <motion.path
        d="M22.5259 78.2091C13.2979 76.2214 6.1654 71.1928 1.00974 63.3132C0.290159 62.2045 0.432493 61.1434 1.32604 60.5494C2.21958 59.9555 3.32662 60.1535 4.0462 61.2146C5.67513 63.5904 7.42268 65.8473 9.59723 67.7559C13.7486 71.3987 18.5247 73.7348 23.9413 74.7881C24.2735 74.8514 24.6056 74.8989 24.9377 74.986C25.9657 75.2553 26.4875 76.0155 26.3452 76.9975C26.2029 77.9636 25.4438 78.6209 24.4316 78.5497C23.799 78.5021 23.1743 78.3279 22.5417 78.2091H22.5259Z"
        fill="#13143a"
        animate={isAnimating ? { scale: [1, 1, 1.1, 0.95, 1.1, 0.98, 1] } : {}}
        transition={{
          repeat: repeat,
          duration: duration,
          ease: "easeInOut",
          delay: 0.2,
        }}
      />
    </motion.svg>
  );
};

export default AnimatedLogo;
