import { Event, SortDefinition } from "../types";

export enum SortDefinitionEnum {
  Distance = "distance",
  TimeAsc = "timeAsc",
  TimeDesc = "timeDesc",
  LocationNameAsc = "locationNameAsc",
  LocationNameDesc = "locationNameDesc",
  EventNameAsc = "eventNameAsc",
  EventNameDesc = "eventNameDesc",
}

function stripQuotes(name: string | null | undefined): string {
  return name ? name.replace(/^"|"$/g, "") : "";
}

export const SORT_DEFINITION: Record<SortDefinitionEnum, SortDefinition> = {
  [SortDefinitionEnum.TimeAsc]: {
    id: SortDefinitionEnum.TimeAsc,
    label: "Soonest",
    compare: (a: Event, b: Event) => {
      const timeA = new Date(a.showDate).getTime() || Number.MAX_SAFE_INTEGER;
      const timeB = new Date(b.showDate).getTime() || Number.MAX_SAFE_INTEGER;
      return timeA - timeB;
    },
    tooltip: "Sort by date, first to last",
  },
  [SortDefinitionEnum.TimeDesc]: {
    id: SortDefinitionEnum.TimeDesc,
    label: "Latest",
    compare: (a: Event, b: Event) => {
      const timeA = new Date(a.showDate).getTime() || Number.MIN_SAFE_INTEGER;
      const timeB = new Date(b.showDate).getTime() || Number.MIN_SAFE_INTEGER;
      return timeB - timeA;
    },
    tooltip: "Sort by date, last to first",
  },
  [SortDefinitionEnum.LocationNameAsc]: {
    id: SortDefinitionEnum.LocationNameAsc,
    label: "Location A-Z",
    compare: (a: Event, b: Event) =>
      (a.stage?.location?.name || "").localeCompare(
        b.stage?.location?.name || "",
      ),
    tooltip: "Sort by location, A to Z",
  },
  [SortDefinitionEnum.LocationNameDesc]: {
    id: SortDefinitionEnum.LocationNameDesc,
    label: "Location Z-A",
    compare: (a: Event, b: Event) =>
      (b.stage?.location?.name || "").localeCompare(
        a.stage?.location?.name || "",
      ),
    tooltip: "Sort by location, Z to A",
  },
  [SortDefinitionEnum.EventNameAsc]: {
    id: SortDefinitionEnum.EventNameAsc,
    label: "Name A-Z",
    compare: (a: Event, b: Event) => {
      const nameA = stripQuotes(a.name ? a.name : "");
      const nameB = stripQuotes(b.name ? b.name : "");
      return nameA.localeCompare(nameB);
    },
    tooltip: "Sort by event name, A to Z",
  },
  [SortDefinitionEnum.EventNameDesc]: {
    id: SortDefinitionEnum.EventNameDesc,
    label: "Name Z-A",
    compare: (a: Event, b: Event) => {
      const nameA = stripQuotes(a.name ? a.name : "");
      const nameB = stripQuotes(b.name ? b.name : "");
      return nameB.localeCompare(nameA);
    },
    tooltip: "Sort by event name, Z to A",
  },
  [SortDefinitionEnum.Distance]: {
    id: SortDefinitionEnum.Distance,
    label: "Closest",
    compare: (a: Event, b: Event) => {
      const distanceA = a.distance ?? Number.MAX_SAFE_INTEGER;
      const distanceB = b.distance ?? Number.MAX_SAFE_INTEGER;
      return distanceA - distanceB;
    },
    tooltip: "Sort by events nearest you",
  },
};

export const SORT_OPTIONS = Object.values(SortDefinitionEnum).map((id) => {
  const tooltip = SORT_DEFINITION[id as SortDefinitionEnum].tooltip;
  return {
    value: id,
    label: SORT_DEFINITION[id as SortDefinitionEnum].label,
    tooltip: tooltip ? tooltip : undefined,
  };
});
