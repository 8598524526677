import React from "react";
import LocalBeatAppBar from "../components/LocalBeatAppBar"; // Assuming this is the correct path
import FooterNav from "../components/FooterNav";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1,
        display: "flex",
        flexDirection: "column",
        backgroundSize: "cover", // Ensure the image covers the whole area
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <LocalBeatAppBar />
      <main className="main-content">{children}</main>
      <FooterNav />
      <footer
        style={{
          padding: "0.15rem .35rem",
          textAlign: "right",
        }}
      >
        <p
          style={{ margin: 0, fontSize: ".75em", color: "rgba(0, 0, 0, 0.5)" }}
        >
          &copy; 2024 LocalBeat. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default Layout;
