import React, { lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Layout from "./pages/Layout";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./util/queryClient";
import Welcome from "./pages/Welcome";
import LoadingAnimation from "./components/LoadingAnimation";
/**
 * lazily import most pages
 */
const Events = lazy(() => import("./pages/Events"));
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Featured = lazy(() => import("./pages/Featured"));
const EventDetails = lazy(() => import("./pages/EventDetails"));
const NoPage = lazy(() => import("./pages/404"));
const UserSettings = lazy(() => import("./pages/UserSettings"));
const CreateUserProfile = lazy(() => import("./pages/CreateUserProfile"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfService = lazy(() => import("./pages/TermsOfService"));

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <BrowserRouter>
            <div className="App">
              <Layout>
                <Suspense fallback={<LoadingAnimation />}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/events" element={<Events />} />
                    <Route path="/featured" element={<Featured />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/welcome" element={<Welcome />} />
                    <Route path="/create" element={<CreateUserProfile />} />
                    <Route path="/me" element={<UserSettings />} />
                    <Route path="/terms" element={<TermsOfService />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/event/:eventId" element={<EventDetails />} />
                    <Route path="*" element={<NoPage />} />
                  </Routes>
                </Suspense>
              </Layout>
            </div>
          </BrowserRouter>
        </ThemeProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;
