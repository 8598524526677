import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = ({ children }: { children: React.ReactNode }) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <a
      style={{ cursor: "pointer" }}
      onClick={() => loginWithRedirect()}
    >
      {children}
    </a>
  );
};

export default LoginButton;
