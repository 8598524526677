import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux"; // Import Provider
import store from "./store"; // Import the store you just created
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

const authProviderConfig: Auth0ProviderOptions = {
  domain: process.env.REACT_APP_AUTH_0_PROVIDER_DOMAIN || "",
  clientId: process.env.REACT_APP_CLIENT_ID || "",
  // audience={API_IDENTIFIER}
  // onRedirectCallback: () => {}, // this will likely need to be developed to maintain user state
  cacheLocation: "localstorage",
  useRefreshTokens: true,
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Auth0Provider {...authProviderConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
);
