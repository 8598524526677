import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchEvents } from "./eventSlice";
import { SortDefinitionEnum } from "../util/SortDefinitions";

interface ListViewState {
  currentPage: number;
  sortDefinition: SortDefinitionEnum;
}

const initialState: ListViewState = {
  currentPage: 1,
  sortDefinition: SortDefinitionEnum.Distance,
};

const listViewSlice = createSlice({
  name: "listView",
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setSortField(state, action: PayloadAction<SortDefinitionEnum>) {
      state.sortDefinition = action.payload;
    },
    resetListView(state) {
      state.currentPage = 1;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.fulfilled, (state) => {
      // Reset listView slice when search results are updated
      state.currentPage = 1;
      state.sortDefinition = SortDefinitionEnum.Distance;
    });
  },
});

export const { setCurrentPage, setSortField, resetListView } =
  listViewSlice.actions;

export default listViewSlice.reducer;
