import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Event, SearchParameters, View } from "../types";
import { searchPrograms } from "../api/search";
import dayjs from "dayjs";

// Constants for default values
const DEFAULT_RADIUS = 10;
const INITIAL_END_DATE_OFFSET_DAYS = 7;

// Set default dates
const defaultStartDate = dayjs().toISOString();
const defaultEndDate = dayjs()
  .add(INITIAL_END_DATE_OFFSET_DAYS, "day")
  .toISOString();

interface EventState {
  searchParams: SearchParameters;
  searchedParams: SearchParameters | null;
  events: Event[];
  loading: boolean;
  view: View;
  zip: string;
  error: string | null;
}

const initialState: EventState = {
  searchParams: {
    coords: undefined,
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    radius: DEFAULT_RADIUS,
    activity: "",
  },
  searchedParams: null,
  events: [],
  loading: false,
  view: View.LIST,
  zip: "",
  error: null,
};

export const fetchEvents = createAsyncThunk(
  "events/fetchEvents",
  async (searchParams: SearchParameters, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState() as { events: EventState };
      if (searchParams === currentState.events.searchedParams) {
        return {
          events: currentState.events.events,
          searchedParams: searchParams,
        };
      }
      const response = await searchPrograms(searchParams);
      let events: Event[] = response.data;
      return { events, searchedParams: searchParams };
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.message);
    }
  },
);

const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setSearchParams(state, action: PayloadAction<Partial<SearchParameters>>) {
      state.searchParams = { ...state.searchParams, ...action.payload };
    },
    setView(state, action: PayloadAction<View>) {
      state.view = action.payload;
    },
    setZip(state, action: PayloadAction<string>) {
      state.zip = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchEvents.fulfilled,
        (
          state,
          action: PayloadAction<{
            events: Event[];
            searchedParams: SearchParameters;
          }>,
        ) => {
          state.loading = false;
          if (Array.isArray(action.payload)) {
            state.events = action.payload;
          } else {
            state.events = action.payload.events;
            state.searchedParams = action.payload.searchedParams; // Save last successful search params
          }
        },
      )
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setSearchParams, setView, setZip } = eventSlice.actions;
export default eventSlice.reducer;
