import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Menu,
  MenuItem,
  Paper,
} from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import AnimatedLogo from "./AnimatedLogo";

// Add array of legal links
const legalLinks = [
  { title: "Terms of Service", path: "/terms" },
  { title: "Privacy Policy", path: "/privacy" },
];

/**
 *
 * @returns Nav menu in footer - has links to legal docs and home page
 */
const FooterNav = () => {
  let [value, setValue] = useState("");
  const styles = {
    background: `linear-gradient(180deg, 
        rgba(255, 255, 255, .5) 0%, 
        rgba(255, 255, 255, 0.85) 65%, 
        rgba(255, 255, 255, 1) 100%
        )`,
    borderRadius: 3,
    width: "100%",
    height: "2.5rem",
  };

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setValue("");
    setAnchorElUser(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        bottom: 15,
        width: "15rem",
        margin: "0 auto",
      }}
    >
      <Paper elevation={3} sx={styles}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={handleChange}
          sx={styles}
        >
          <BottomNavigationAction
            label="About"
            component={RouterLink}
            to="/about"
            value="/about"
          />
          <BottomNavigationAction
            label=""
            icon={<AnimatedLogo size="3em" repeat={1} clickable={true} />}
            component={RouterLink}
            to="/"
            value="/"
          />
          <BottomNavigationAction label="Legal" onClick={handleOpenUserMenu} />
        </BottomNavigation>
      </Paper>
      <Menu
        sx={{ mt: -1 }}
        id="legal-menu"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {legalLinks.map((link) => (
          <MenuItem
            sx={{ fontSize: "0.8rem", m: 0 }}
            key={link.path}
            onClick={handleCloseUserMenu}
            component={RouterLink}
            to={link.path}
          >
            {link.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default FooterNav;
