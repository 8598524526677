export function debugLog(message: any, ...optionalParams: any[]): void {
  if (process.env.NODE_ENV === "development") {
    const stack = new Error().stack;
    let location = "";

    if (stack) {
      const stackLines = stack.split("\n");
      // The first two lines are irrelevant, extract the third one
      const callerLine = stackLines[2]?.trim();
      if (callerLine) {
        // Extract file name and line number
        const match = callerLine.match(/\((.*?):(\d+):\d+\)$/);
        if (match) {
          location = `(${match[1]}:${match[2]})`;
        }
      }
    }

    console.log(`[DEBUG]${location}:`, message, ...optionalParams);
  }
}
