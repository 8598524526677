import { Box } from "@mui/material";
import AnimatedLogo from "./AnimatedLogo";

const LoadingAnimation = ({size = "8rem"}: {size?: string}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "40vh",
        margin: "0 auto",
      }}
    >
      <AnimatedLogo size={size} repeat={Infinity} clickable={false} />
    </Box>
  );
};

export default LoadingAnimation;
