import { configureStore } from "@reduxjs/toolkit";
import listViewReducer from "./listViewSlice";
import eventReducer from "./eventSlice";
import categoryReducer from "./categorySlice";

const store = configureStore({
  reducer: {
    events: eventReducer,
    categories: categoryReducer,
    listView: listViewReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
